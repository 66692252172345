import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { login } from '../../redux/actions';
import AuthPage from './AuthPage';
import backend from '../../apis/backend';
import classnames from 'classnames';

function LoginPage({ history, login }) {
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState(null);
  const [loading, setLoading] = useState(null);

  async function onSubmit(data) {
    if (loading) return;

    setLoading(true);

    try {
      const response = await backend.post('/login', data);
      window.localStorage.setItem('token', response.data.data.access_token);
      login(response.data.data.user);
      history.push('/webapp/console/transactions');
    } catch (e) {
      setFormError(e?.data?.error?.description || 'Request failed');
      setLoading(false);
    }
  }

  return (
    <AuthPage>
      <div className="heading-container">
        <h2>Sign In</h2>
      </div>

      <form className="dbx-2 form" onSubmit={handleSubmit(onSubmit)}>
        {formError && (
          <div className="main-error-message">
            <span>
              <i className="fas fa-exclamation-triangle"></i>
            </span>
            <p>* {formError}</p>
          </div>
        )}

        <div className="group">
          <label className="dbx-2">Email Address</label>
          <input className="dbx-2" type="text" autoFocus inputMode="email" name="email" ref={register} />
        </div>

        <div className="group password">
          <label className="dbx-2">Password</label>
          <div className="input-container">
            <input className="dbx-2" type="password" name="password" ref={register} />
          </div>
        </div>

        <div className="bottom">
          <button type="submit" className={classnames('dbx-2 blue button', { loading })}>
            Sign in
          </button>
          <Link to="/webapp/auth/forgot-password" className="forgot-password">
            Forgot Password?
          </Link>
        </div>
      </form>
    </AuthPage>
  );
}

export default connect(null, { login })(LoginPage);
