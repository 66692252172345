import logo from '../../assets/img/logo-2.svg';
import illustration from '../../assets/img/illustration-main.svg';
import wallet from '../../assets/img/mobile-wallet.svg';
import { Link } from 'react-router-dom';

export default function HomePage() {
  return (
    <div className="HomePage alt-font-family">
      <div className="header">
        <div className="big-circle"></div>
        <nav className="mobile">
          <ul>
            <li>
              <a href="#0">SERVICES</a>
            </li>
            <li>
              <a href="#0">DOCS</a>
            </li>
            <li>
              <a href="#0">CONTACT</a>
            </li>
            <li>
              <a href="#0">SIGN IN</a>
            </li>
          </ul>
        </nav>

        <a href="/" className="site-logo">
          <img src={logo} alt="Logo" />
        </a>

        <nav className="main">
          <ul>
            <li>
              <a href="#0">SERVICES</a>
            </li>
            <li>
              <a href="#0">DOCS</a>
            </li>
            <li>
              <a href="#0">CONTACT</a>
            </li>
            <li>
              <Link to="/webapp/auth/login">SIGN IN</Link>
            </li>
          </ul>
        </nav>

        <a href="#0" className="dbx get-started">
          <div className="circles">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          <span>GET STARTED</span>
        </a>

        <button className="nav-trigger">
          <div className="circles">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </button>
      </div>

      <div className="banner">
        <img src={illustration} alt="" className="illustration" />

        <div className="main">
          <h2>
            <span>Hello,</span>
            <br />
            Build exciting
            <br /> Payment Services with
            <br /> our easy-to-use APIs.
          </h2>
          <p>Devbox provides developers with simple APIs accessible through seamless onboarding at no charge.</p>

          <div className="buttons">
            <a href="#0" className="dbx get-started">
              <div className="circles">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
              <span>GET STARTED</span>
            </a>

            <a href="#0" className="dbx view-docs">
              <div className="circles">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
              <span>VIEW DOCS</span>
            </a>
          </div>
        </div>
      </div>

      <section className="services">
        <h2>Services</h2>
        <ul>
          <li className="service">
            <p className="name">E-Money Services</p>
            <div className="main">
              <img src={wallet} alt="" />
              <p>Devbox provides developers with simple APIs accessible through seamless onboarding at no charge.</p>
            </div>
          </li>

          <li className="service">
            <p className="name">E-Money Services</p>
            <div className="main">
              <img src={wallet} alt="" />
              <p>Devbox provides developers with simple APIs accessible through seamless onboarding at no charge.</p>
            </div>
          </li>

          <li className="service">
            <p className="name">E-Money Services</p>
            <div className="main">
              <img src={wallet} alt="" />
              <p>Devbox provides developers with simple APIs accessible through seamless onboarding at no charge.</p>
            </div>
          </li>

          <li className="service">
            <p className="name">E-Money Services</p>
            <div className="main">
              <img src={wallet} alt="" />
              <p>Devbox provides developers with simple APIs accessible through seamless onboarding at no charge.</p>
            </div>
          </li>
        </ul>
      </section>
      <div style={{ height: '400px' }} />
    </div>
  );
}
