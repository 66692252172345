import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function RequireAdminRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return <Redirect to={{ pathname: '/webapp/auth/login', state: { from: props.location } }} />;
        }

        if (!['SUPER_ADMIN', 'ADMIN'].includes(user.role)) {
          return <Redirect to={{ pathname: '/webapp/console/transactions', state: { from: props.location } }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps)(RequireAdminRoute);
