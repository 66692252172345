import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import './assets/css/normalize.css';
import 'toasted-notes/src/styles.css';
import './assets/css/common.css';
import './assets/css/styles.css';
import './assets/css/new_styles.css';

import App from './App';
import reducer from './redux/reducers';
const store = createStore(reducer, {}, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
