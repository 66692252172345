import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';

import backend from '../../apis/backend';
import { Link } from 'react-router-dom';
import AuthPage from './AuthPage';

export default function SetPasswordPage({ history }) {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [completed, setCompleted] = useState(false);
  const { register, handleSubmit } = useForm();

  async function onSubmit(data) {
    setFormError(null);
    setLoading(true);

    try {
      await backend.post('/token', data);
      setCompleted(true);
    } catch (e) {
      setFormError(e?.data?.error?.description || 'Request failed');
    } finally {
      setLoading(false);
    }
  }

  return (
    <AuthPage>
      {completed ? (
        <div className="completed">
          <h2>Check your inbox</h2>
          <p>
            A password reset link has been sent to your email. Check your inbox and follow the prompt to reset your
            password.
          </p>
          <Link to="/webapp/auth/login" className="dbx-2 blue button">
            Log in <i className="fas fa-arrow-right"></i>
          </Link>
        </div>
      ) : (
        <>
          <div className="heading-container">
            <h2>Forgot password</h2>
          </div>

          <form className="dbx-2 form" onSubmit={handleSubmit(onSubmit)}>
            {formError && (
              <div className="main-error-message">
                <span>
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
                <p>* {formError}</p>
              </div>
            )}

            <div className="group">
              <label className="dbx-2">Your email</label>
              <input className="dbx-2" type="text" autoFocus inputMode="email" name="email" ref={register} />
            </div>

            <div className="bottom">
              <button type="submit" className={classnames('dbx-2 blue button', { loading })}>
                Reset password
              </button>
            </div>
          </form>
        </>
      )}
    </AuthPage>
  );
}
