import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import backend from './apis/backend';
import { login } from './redux/actions';

// Pages
import DocsHomePage from './pages/docs/home';
import ReadDocsPage from './pages/docs/read';
import TransactionsPage from './pages/console/TransactionsPage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import SetPasswordPage from './pages/auth/SetPasswordPage';
import RequireLoginRoute from './components/RequireLoginRoute';
import RequireLogoutRoute from './components/RequireLogoutRoute';
import UsersPage from './pages/console/UsersPage';
import AddUserPage from './pages/console/AddUserPage';
import UpdateUserPage from './pages/console/UpdateUserPage';
import ConfigPage from './pages/console/ConfigPage';
import LoadingPage from './pages/LoadingPage';
import ProfilePage from './pages/console/ProfilePage';
import ChangePasswordPage from './pages/auth/ChangePasswordPage';
import HomePage from './pages/HomePage';
import EditConfigPage from './pages/console/EditConfigPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import RequireAdminRoute from './components/RequireAdminRoute';

function App({ user, login }) {
  useEffect(() => {
    async function tryLocalLogin() {
      try {
        const response = await backend.get('/login');
        login(response.data.data);
      } catch (e) {
        login(false);
      }
    }

    tryLocalLogin();
  }, [login]);

  if (user === null) return <LoadingPage />;

  return (
    <BrowserRouter>
      <Switch>
        {/* <Route path="/" exact component={HomePage} /> */}
        <Route path="/webapp" exact component={HomePage} />
        <RequireLogoutRoute path="/webapp/auth/login" exact component={LoginPage} />
        <RequireLogoutRoute path="/webapp/auth/register" exact component={RegisterPage} />
        <RequireLogoutRoute path="/webapp/auth/set-password" exact component={SetPasswordPage} />
        <RequireLogoutRoute path="/webapp/auth/forgot-password" exact component={ForgotPasswordPage} />
        <RequireLoginRoute path="/webapp/auth/change-password" exact component={ChangePasswordPage} />
        <Route path="/webapp/docs" exact component={DocsHomePage} />
        <RequireLoginRoute path="/webapp/docs/read" exact component={ReadDocsPage} />
        <Route path="/webapp/console/transactions" exact component={TransactionsPage} />
        <RequireAdminRoute path="/webapp/console/users" exact component={UsersPage} />
        <RequireAdminRoute path="/webapp/console/users/add" exact component={AddUserPage} />
        <RequireAdminRoute path="/webapp/console/users/:id" exact component={UpdateUserPage} />
        <RequireLoginRoute path="/webapp/console/profile" exact component={ProfilePage} />
        <RequireLoginRoute path="/webapp/console/config" exact component={ConfigPage} />
        <RequireAdminRoute path="/webapp/console/config/edit" exact component={EditConfigPage} />
      </Switch>
    </BrowserRouter>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps, { login })(App);
