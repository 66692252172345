import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import classnames from 'classnames';
import { login } from '../../redux/actions';
import AuthPage from './AuthPage';
import backend from '../../apis/backend';
import OrganizationSignUp from './OrganizationSignUp';
import IndividualSignUp from './IndividualSignUp';

function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [formError, setFormError] = useState(null);
  const methods = useForm();
  const { register, handleSubmit, watch } = methods;

  const category = watch('category', 'INDIVIDUAL');

  async function onSubmit(data) {
    if (data.category === 'INDIVIDUAL') data.name = data.contact.name;

    setFormError(null);
    setLoading(true);

    try {
      await backend.post('/institutions', data);
      setCompleted(true);
    } catch (e) {
      setFormError(e?.data?.error?.description || 'Request failed');
    } finally {
      setLoading(false);
    }
  }

  const categoryProps = {
    type: 'radio',
    name: 'category',
    ref: register({ required: 'Select category' }),
  };

  return (
    <AuthPage>
      {completed ? (
        <div className="completed">
          <h2>Registration Successful</h2>
          <p>Please check your email and follow the instructions to complete registration.</p>
        </div>
      ) : (
        <div className="SignUpPage">
          <div className="heading-container">
            <h2>Sign Up</h2>
          </div>

          <form className="dbx-2 form" onSubmit={handleSubmit(onSubmit)}>
            {formError && (
              <div className="main-error-message">
                <span>
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
                <p>* {formError}</p>
              </div>
            )}

            <div className="group">
              <label className="dbx-2">Sign up as</label>

              <div className="dbx-2 radio">
                <input id="individual" value="INDIVIDUAL" {...categoryProps} defaultChecked />
                <label htmlFor="individual">Individual</label>
              </div>

              <div className="dbx-2 radio">
                <input id="organization" value="ORGANIZATION" {...categoryProps} />
                <label htmlFor="organization">Organization</label>
              </div>
            </div>

            <FormProvider {...methods}>
              {category === 'INDIVIDUAL' ? <IndividualSignUp /> : <OrganizationSignUp />}
            </FormProvider>

            <div className="bottom">
              <button type="submit" className={classnames('dbx-2 blue button', { loading })}>
                Sign up
              </button>
            </div>
          </form>
        </div>
      )}
    </AuthPage>
  );
}

export default connect(null, { login })(LoginPage);
