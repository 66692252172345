import React, { useEffect, useRef, useState } from 'react';
import logo from '../assets/img/logo.svg';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, setLive } from '../redux/actions';
import { CSSTransition } from 'react-transition-group';
import toaster from 'toasted-notes';
import classnames from 'classnames';
import backend from '../apis/backend';

function Header({ user, logout, setLive }) {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const [userDropdownVisible, setUserDropdownVisible] = useState(false);
  const mobileNav = useRef(null);
  const userDropdown = useRef(null);
  const [liveRequested, setLiveRequested] = useState(false);
  const isAdmin = user && ['SUPER_ADMIN', 'ADMIN'].includes(user.role);

  useEffect(() => {
    function handler(e) {
      if (mobileNav.current && !mobileNav.current.contains(e.target)) {
        setTimeout(() => setMobileNavVisible(false), 10);
      }
    }

    window.addEventListener('click', handler, true);
    return () => window.removeEventListener('click', handler, true);
  }, []);

  useEffect(() => {
    function handler(e) {
      if (userDropdown.current && !userDropdown.current.contains(e.target)) {
        setTimeout(() => setUserDropdownVisible(false), 10);
      }
    }

    window.addEventListener('click', handler, true);
    return () => window.removeEventListener('click', handler, true);
  }, []);

  function renderNavItems() {
    return (
      <ul className="disable-selection">
        <li>
          <NavLink to="/webapp/docs">Docs</NavLink>
        </li>
        {user && (
          <li>
            <NavLink to="/webapp/console/transactions">Console</NavLink>
          </li>
        )}
      </ul>
    );
  }

  async function goLive() {
    if (liveRequested) return;
    setLiveRequested(true);

    try {
      await backend.get(`/institutions/${user.institution.id}/go-live`);
      setLive();
      toaster.notify('You have successfully been set up for live.', { position: 'bottom-right' });
    } catch (e) {
      toaster.notify('Sorry, something went wrong.', { position: 'bottom-right' });
      setLiveRequested(false);
    }
  }

  return (
    <header>
      <div className="wrapper">
        <h1 className="logo">
          <Link to="/webapp">
            <img src={logo} alt="DevBox Logo" />
          </Link>
        </h1>

        <div className="right">
          <nav className="main-nav">{renderNavItems()}</nav>
          <ul className="auth-btns">
            {user ? (
              isAdmin &&
              !user.institution.is_live && (
                <li>
                  <button
                    type="button"
                    onClick={goLive}
                    className={classnames('dbx orange button', { loading: liveRequested })}
                  >
                    Go Live!
                  </button>
                </li>
              )
            ) : (
              <>
                <li className="login">
                  <Link to="/webapp/auth/login">Log in</Link>
                </li>
                <li className="signup">
                  <Link to="/webapp/auth/register">Sign up</Link>
                </li>
              </>
            )}
          </ul>
          {user && (
            <div className="user-dropdown">
              <span className="user-icon" onClick={() => setUserDropdownVisible((v) => !v)}>
                <i className="fas fa-user"></i>
              </span>
              <CSSTransition in={userDropdownVisible} unmountOnExit timeout={200}>
                <ul className="disable-selection" ref={userDropdown}>
                  <li>
                    <Link to="/webapp/console/profile">Profile</Link>
                  </li>
                  <li>
                    <Link to="#0" onClick={() => logout()}>
                      Logout
                    </Link>
                  </li>
                </ul>
              </CSSTransition>
            </div>
          )}
          <CSSTransition in={mobileNavVisible} unmountOnExit timeout={200}>
            <nav className="mobile-nav" ref={mobileNav}>
              {renderNavItems()}
            </nav>
          </CSSTransition>
          <span className="nav-trigger" onClick={() => setMobileNavVisible((v) => !v)}>
            <i className="fas fa-bars"></i>
          </span>
        </div>
      </div>
    </header>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps, { logout, setLive })(Header);
