import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://devbox.nsano.com/engine/v1',
});

instance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use((response) => {
  if (response?.data?.code !== '00') return Promise.reject(response);

  return response;
});

export default instance;
