import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';

function ConsoleSideBar({ user }) {
  const isAdmin = ['SUPER_ADMIN', 'ADMIN'].includes(user.role);

  return (
    <Sidebar>
      <nav>
        <ul>
          <li className="section-title">
            <Link to="#0">
              <span>
                <i className="fas fa-columns"></i>
              </span>
              Dashboard
            </Link>
          </li>
          <li className="section-title">
            <Link to="/webapp/console/transactions">
              <span>
                <i className="fas fa-coins"></i>
              </span>
              Transactions
            </Link>
          </li>
          {isAdmin && (
            <li className="section-title">
              <Link to="/webapp/console/users">
                <span>
                  <i className="fas fa-users"></i>
                </span>
                Users
              </Link>
            </li>
          )}
          <li className="section-title">
            <Link to="#0">
              <span>
                <i className="fas fa-cogs"></i>
              </span>
              Settings
            </Link>
          </li>
          <li>
            <Link to="/webapp/console/config">Config</Link>
          </li>
        </ul>
      </nav>
    </Sidebar>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps)(ConsoleSideBar);
