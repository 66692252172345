import * as types from '../types';
const initialState = { user: null };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN: {
      return { ...state, user: action.payload };
    }

    case types.LOGOUT: {
      return { ...state, user: false };
    }

    case types.SET_LIVE: {
      return { ...state, user: { ...state.user, institution: { ...state.user.institution, is_live: true } } };
    }

    default:
      return state;
  }
}
