import * as types from '../types';

export function login(payload) {
  return { type: types.LOGIN, payload };
}

export function logout() {
  window.localStorage.removeItem('token');

  return { type: types.LOGOUT };
}

export function setLive() {
  console.log('run');
  return { type: types.SET_LIVE };
}
