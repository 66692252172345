import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import ConsoleSideBar from './ConsoleSideBar';
import backend from '../../apis/backend';
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';

function ConfigPage({ user }) {
  const [config, setConfig] = useState(null);
  const isAdmin = ['SUPER_ADMIN', 'ADMIN'].includes(user.role);

  useEffect(() => {
    async function fetchConfig() {
      try {
        const response = await backend.get(`/institutions/${user.institution.id}/config`);
        setConfig(response.data.data);
      } catch (e) {}
    }
    fetchConfig();
    // eslint-disable-next-line
  }, []);

  function renderSandboxConfig() {
    return (
      <section>
        <h2 className="heading-1">Config</h2>
        <div className="transactions-table">
          <table>
            <tbody>
              <tr>
                <td>API Key</td>
                <td></td>
              </tr>
              <tr>
                <td>Credit Callback URL</td>
                <td>{config.credit_callback_url}</td>
              </tr>
              <tr>
                <td>Debit Callback URL</td>
                <td>{config.debit_callback_url}</td>
              </tr>
              <tr>
                <td>IP Address</td>
                <td>{config.ip_address}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    );
  }

  function renderMsisdnWhitelist() {
    return (
      <section className="msisdn">
        <h2 className="heading-1">Allowed MSISDN</h2>
        {config.msisdn_whitelist.length ? (
          <ul>
            {config.msisdn_whitelist.map((msisdn) => (
              <li key={Math.random()}>{msisdn}</li>
            ))}
          </ul>
        ) : (
          <p className="empty-list-message">You currently have no allowed numbers.</p>
        )}
      </section>
    );
  }

  function renderBottom() {
    return (
      <div className="bottom">
        <div className="actions">
          <Link to="/webapp/console/config/edit" className="dbx blue text button">
            Edit config
          </Link>
        </div>
      </div>
    );
  }

  function renderContent() {
    return (
      <>
        {renderSandboxConfig()}
        {renderMsisdnWhitelist()}
        {isAdmin && renderBottom()}
      </>
    );
  }

  return (
    <div id="config-page">
      <Header />
      <main>
        <ConsoleSideBar />
        <div className="main-content">
          <div className="sidebar-aware">
            <div className="header-aware">
              {config === null ? <Loading text="Fetching config..." /> : renderContent()}
            </div>
          </div>
        </div>
      </main>

      <div style={{ height: '200px' }}></div>
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps)(ConfigPage);
