import React from 'react';
import Header from '../../../components/Header';
import { Link } from 'react-router-dom';
import HashLinkWithScroll from '../../../components/HashLinkWithScroll';

export default function DocsHomePage() {
  return (
    <div className="header-aware">
      <div id="docs-home-page">
        <Header />

        <div className="banner">
          <p className="title">Documentation</p>
          <p className="tagline">Everything you need to get your software documentation online.</p>
        </div>

        <div className="docs-sections">
          <ul className="sections">
            <li className="section">
              <Link to="/webapp/docs/read">
                <div className="top">
                  <span>
                    <i className="fas fa-file-alt"></i>
                  </span>
                  <p className="title">Introduction</p>
                </div>
                <p className="description">
                  Section overview goes here. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
                  viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet
                </p>
              </Link>
            </li>

            <li className="section">
              <HashLinkWithScroll to="/webapp/docs/read#section-1">
                <div className="top">
                  <span>
                    <i className="fas fa-file-alt"></i>
                  </span>
                  <p className="title">Mobile Money</p>
                </div>
                <p className="description">
                  Section overview goes here. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
                  viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet
                </p>
              </HashLinkWithScroll>
            </li>

            <li className="section">
              <Link to="/webapp/docs/read">
                <div className="top">
                  <span>
                    <i className="fas fa-file-alt"></i>
                  </span>
                  <p className="title">Card Payments</p>
                </div>
                <p className="description">
                  Section overview goes here. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
                  viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet
                </p>
              </Link>
            </li>

            <li className="section">
              <Link to="/webapp/docs/read">
                <div className="top">
                  <span>
                    <i className="fas fa-file-alt"></i>
                  </span>
                  <p className="title">Bank Terminations</p>
                </div>
                <p className="description">
                  Section overview goes here. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
                  viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet
                </p>
              </Link>
            </li>

            <li className="section">
              <HashLinkWithScroll to="/webapp/docs/read#section-2">
                <div className="top">
                  <span>
                    <i className="fas fa-file-alt"></i>
                  </span>
                  <p className="title">Airtime & Data</p>
                </div>
                <p className="description">
                  Section overview goes here. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
                  viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet
                </p>
              </HashLinkWithScroll>
            </li>

            <li className="section">
              <Link to="/webapp/docs/read">
                <div className="top">
                  <span>
                    <i className="fas fa-file-alt"></i>
                  </span>
                  <p className="title">Utilities</p>
                </div>
                <p className="description">
                  Section overview goes here. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
                  viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet
                </p>
              </Link>
            </li>

            <li className="section">
              <HashLinkWithScroll to="/webapp/docs/read#section-2">
                <div className="top">
                  <span>
                    <i className="fas fa-file-alt"></i>
                  </span>
                  <p className="title">Subscriptions</p>
                </div>
                <p className="description">
                  Section overview goes here. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
                  viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet
                </p>
              </HashLinkWithScroll>
            </li>

            <li className="section">
              <Link to="/webapp/docs/read">
                <div className="top">
                  <span>
                    <i className="fas fa-file-alt"></i>
                  </span>
                  <p className="title">SMS</p>
                </div>
                <p className="description">
                  Section overview goes here. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
                  viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet
                </p>
              </Link>
            </li>

            <li className="section">
              <Link to="/webapp/docs/read">
                <div className="top">
                  <span>
                    <i className="fas fa-file-alt"></i>
                  </span>
                  <p className="title">USSD</p>
                </div>
                <p className="description">
                  Section overview goes here. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus
                  viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet
                </p>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div style={{ height: '200px' }}></div>
    </div>
  );
}
