import React from 'react';
import { useFormContext } from 'react-hook-form';
import ghanaFlag from '../../assets/img/ghana-flag.png';

function IndividualSignUp() {
  const { register } = useFormContext();

  return (
    <>
      <input name="category" value="INDIVIDUAL" hidden readOnly />

      <div className="group">
        <label className="dbx-2">Your Name:</label>
        <input className="dbx-2" type="text" autoFocus name="contact.name" ref={register} />
      </div>

      <div className="group">
        <label className="dbx-2">Email Address</label>
        <input className="dbx-2" type="text" inputMode="email" name="contact.email" ref={register} />
      </div>

      <div className="group">
        <label className="dbx-2">Phone number</label>
        <input className="dbx-2" type="text" inputMode="tel" name="contact.msisdn" ref={register} />
      </div>

      <div className="group country">
        <label className="dbx-2">Country</label>

        <div className="input-container">
          <div className="dbx-2 select">
            <select name="country" ref={register}>
              <option value="GH">Ghana</option>
            </select>
          </div>
          <img src={ghanaFlag} alt="" />
        </div>
      </div>
    </>
  );
}

export default IndividualSignUp;
