import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import ConsoleSideBar from './ConsoleSideBar';
import backend from '../../apis/backend';
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';

export default function ProfilePage({ history }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await backend.get('/login');
        setUser(response.data.data);
      } catch (e) {}
    }

    fetchUser();
  }, []);

  return (
    <div id="profile-page">
      <Header />
      <main>
        <ConsoleSideBar />
        <div className="main-content">
          <div className="sidebar-aware">
            <div className="header-aware">
              {user === null ? (
                <Loading text="Loading profile..." />
              ) : (
                <>
                  <div className="top">
                    <h2 className="heading-1">Profile</h2>
                  </div>
                  <ul className="profile">
                    <li>
                      <p className="key">Name</p>
                      <p className="value">{user.name}</p>
                    </li>

                    <li>
                      <p className="key">Email</p>
                      <p className="value">{user.email}</p>
                    </li>

                    <li>
                      <p className="key">Contact number</p>
                      <p className="value">{user.msisdn}</p>
                    </li>

                    <li>
                      <p className="key">Country</p>
                      <p className="value">{user.institution.country}</p>
                    </li>
                  </ul>

                  <div className="bottom">
                    <div className="actions">
                      <Link to={`/webapp/console/users/${user.id}`} className="dbx blue text button">
                        Edit profile
                      </Link>

                      <Link to="/webapp/auth/change-password" className="dbx blue text button">
                        Change password
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>

      <div style={{ height: '200px' }}></div>
    </div>
  );
}
