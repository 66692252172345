import React, { useEffect, useRef, useState } from 'react';
import qs from 'query-string';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';

import backend from '../../apis/backend';
import { Link } from 'react-router-dom';
import LoadingPage from '../LoadingPage';
import AuthPage from './AuthPage';

export default function SetPasswordPage({ history }) {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(null);
  const [completed, setCompleted] = useState(false);
  const { register, handleSubmit } = useForm();

  const params = useRef(qs.parse(history.location.search));

  useEffect(() => {
    setFormError(null);

    async function verifyToken() {
      try {
        await backend.get(`/token/${params.current.token}`);
        setIsTokenValid(true);
      } catch (e) {
        setIsTokenValid(false);
        setFormError('Invalid Token');
      }
    }

    verifyToken();
  }, []);

  async function onSubmit(data) {
    setFormError(null);

    if (data.password !== data.confirm_password) {
      return setFormError('Passwords do not match');
    }

    setLoading(true);
    try {
      await backend.post('/users/set-password', { password: data.password, token: params.current.token });
      setCompleted(true);
    } catch (e) {
      setFormError(e?.data?.error?.description || 'Request failed');
    } finally {
      setLoading(false);
    }
  }

  if (isTokenValid === null) return <LoadingPage />;

  return (
    <AuthPage>
      {completed ? (
        <div className="completed">
          <h2>Registration Complete!</h2>
          <p>Your password has been set. Log in to your account to get started.</p>
          <Link to="/webapp/auth/login" className="dbx-2 blue button">
            Sign in <i className="fas fa-arrow-right"></i>
          </Link>
        </div>
      ) : (
        <>
          <div className="heading-container">
            <h2>Set your password</h2>
          </div>

          <form className="dbx-2 form" onSubmit={handleSubmit(onSubmit)}>
            {formError && (
              <div className="main-error-message">
                <span>
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
                <p>* {formError}</p>
              </div>
            )}

            <div className="group">
              <label className="dbx-2">Password</label>
              <div className="input-container">
                <input className="dbx-2" type="password" name="password" autoFocus ref={register} />
              </div>
            </div>

            <div className="group">
              <label className="dbx-2">Confirm Password</label>
              <div className="input-container">
                <input className="dbx-2" type="password" name="confirm_password" ref={register} />
              </div>
            </div>

            <div className="bottom">
              <button type="submit" className={classnames('dbx-2 blue button', { loading })}>
                Set Password
              </button>
            </div>
          </form>
        </>
      )}
    </AuthPage>
  );
}
