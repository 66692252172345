import React, { useState } from 'react';
import Header from '../../components/Header';
import { useForm } from 'react-hook-form';
import backend from '../../apis/backend';
import classnames from 'classnames';
import { connect } from 'react-redux';

function AddUserPage({ user, history }) {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const { register, handleSubmit } = useForm();

  async function onSubmit(data) {
    setFormError(null);
    setLoading(true);

    data = { ...data, institution: user.institution.id };

    try {
      await backend.post('/users', data);
      history.push('/webapp/console/users');
    } catch (e) {
      setFormError(e?.data?.error?.description || 'Request failed');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div id="register-page" className="auth-page">
      <Header />
      <div className="header-aware">
        <div className="intro">
          <h2>Add User</h2>
        </div>
        <form className="dbx form" onSubmit={handleSubmit(onSubmit)}>
          {formError && (
            <div className="main-error-message">
              <span>
                <i className="fas fa-exclamation-triangle"></i>
              </span>
              <p>* {formError}</p>
            </div>
          )}

          <div className="group">
            <label className="dbx">Name of user:</label>
            <div className="dbx input-container">
              <input type="text" className="dbx" name="name" ref={register} />
              <i className="fas fa-address-card"></i>
            </div>
          </div>

          <div className="group">
            <label className="dbx">Email:</label>
            <div className="dbx input-container">
              <input type="text" className="dbx" inputMode="email" name="email" ref={register} />
              <i className="fas fa-envelope"></i>
            </div>
          </div>

          {/* <div className="group">
            <label className="dbx">Password:</label>
            <div className="dbx input-container">
              <input type="password" className="dbx" name="password" ref={register} />
              <i className="fas fa-key"></i>
            </div>
          </div> */}

          <div className="group">
            <label className="dbx">Contact number:</label>
            <div className="dbx input-container">
              <input type="text" className="dbx" inputMode="tel" name="msisdn" ref={register} />
              <i className="fas fa-phone-alt"></i>
            </div>
          </div>

          <div className="group">
            <label className="dbx">Role:</label>
            <div className="dbx select">
              <select name="role" ref={register}>
                <option value="USER">User</option>
                <option value="ADMIN">Admin</option>
              </select>
              <i className="fas fa-level-up-alt"></i>
            </div>
          </div>

          <div style={{ height: '8px' }} />
          <button
            type="submit"
            className={classnames('dbx green rounded right button', { loading })}
            disabled={loading}
          >
            Register <i className="fas fa-arrow-right"></i>
          </button>
        </form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps)(AddUserPage);
