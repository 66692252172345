import React, { useEffect, useRef } from 'react';
import postscribe from 'postscribe';

export default function GithubGist({ src }) {
  const div = useRef(null);

  useEffect(() => {
    postscribe(div.current, `<script src="${src}"></script>`);
  }, [src]);

  return <div className="docs-gist" ref={div}></div>;
}
