import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import ConsoleSideBar from './ConsoleSideBar';
import backend from '../../apis/backend';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';

export default function UsersPage({ history }) {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await backend.get('/users');
        setUsers(response.data.data);
      } catch (e) {}
    }

    fetchUsers();
  }, []);

  return (
    <div id="users-page">
      <Header />
      <main>
        <ConsoleSideBar />
        <div className="main-content">
          <div className="sidebar-aware">
            <div className="header-aware">
              <div className="top">
                <Link to="/webapp/console/users/add" className="dbx green rounded right button">
                  Add User <i className="fas fa-user-plus"></i>
                </Link>
              </div>
              <div className="transactions-table">
                <table>
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact</th>
                      <th scope="col">Role</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((user) => (
                      <tr
                        key={user.email}
                        onClick={() => {
                          history.push(`/webapp/console/users/${user.id}`);
                        }}
                      >
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.msisdn}</td>
                        <td>{user.role}</td>
                        <td>{user.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {users === null && <Loading text="Fetching users..." />}
              </div>
            </div>
          </div>
        </div>
      </main>

      <div style={{ height: '200px' }}></div>
    </div>
  );
}
