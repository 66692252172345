import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { useForm } from 'react-hook-form';
import backend from '../../apis/backend';
import classnames from 'classnames';
import LoadingPage from '../LoadingPage';

export default function EditUserPage({ history, match }) {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [user, setUser] = useState(null);
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await backend.get(`/users/${match.params.id}`);

        setUser(response.data.data);

        const { name, msisdn, role } = response.data.data;
        setValue('name', name);
        setValue('msisdn', msisdn);
        setValue('role', role);
      } catch (e) {
        setFormError(e?.data?.error?.description || 'Request failed');
      }
    }
    fetchUser();
  }, [match.params.id, setValue]);

  async function onSubmit(data) {
    if (!user) return;

    setFormError(null);
    setLoading(true);

    try {
      await backend.put(`/users/${user.id}`, data);
      history.push('/webapp/console/users');
    } catch (e) {
      setFormError(e?.data?.error?.description || 'Request failed');
    } finally {
      setLoading(false);
    }
  }

  if (user === null) return <LoadingPage text="Fetching user..." />;

  return (
    <div id="register-page" className="auth-page">
      <Header />
      <div className="header-aware">
        <div className="intro">
          <h2>Update User</h2>
        </div>
        <form className="dbx form" onSubmit={handleSubmit(onSubmit)}>
          {formError && (
            <div className="main-error-message">
              <span>
                <i className="fas fa-exclamation-triangle"></i>
              </span>
              <p>* {formError}</p>
            </div>
          )}

          <div className="group">
            <label className="dbx">Name of user:</label>
            <div className="dbx input-container">
              <input type="text" className="dbx" name="name" ref={register} />
              <i className="fas fa-address-card"></i>
            </div>
          </div>

          <div className="group">
            <label className="dbx">Contact number:</label>
            <div className="dbx input-container">
              <input type="text" className="dbx" inputMode="tel" name="msisdn" ref={register} />
              <i className="fas fa-phone-alt"></i>
            </div>
          </div>

          {['SUPER_ADMIN', 'ADMIN'].includes(user.role) && (
            <div className="group">
              <label className="dbx">Role:</label>
              <div className="dbx select">
                <select name="role" ref={register}>
                  <option value="USER">User</option>
                  <option value="ADMIN">Admin</option>
                </select>
                <i className="fas fa-level-up-alt"></i>
              </div>
            </div>
          )}

          <div style={{ height: '8px' }} />
          <button
            type="submit"
            className={classnames('dbx green rounded right button', { loading })}
            disabled={loading}
          >
            Save changes <i className="fas fa-save"></i>
          </button>
        </form>
      </div>
    </div>
  );
}
