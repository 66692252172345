import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { useForm, useFieldArray } from 'react-hook-form';
import backend from '../../apis/backend';
import classnames from 'classnames';
import LoadingPage from '../LoadingPage';
import { connect } from 'react-redux';

function EditConfigPage({ user, history }) {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const { register, handleSubmit, setValue, control, watch } = useForm({
    defaultValues: { configs: {}, msisdn_whitelist: [] },
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'msisdn_whitelist' });

  const [config, setConfig] = useState(null);
  const msisdn_whitelist = watch('msisdn_whitelist', fields);

  useEffect(() => {
    async function fetchConfig() {
      try {
        const response = await backend.get(`/institutions/${user.institution.id}/config`);
        setConfig(response.data.data);

        const keys = ['credit_callback_url', 'debit_callback_url', 'ip_address'];
        const { msisdn_whitelist, ...data } = response.data.data;
        for (const key of keys) {
          setValue(`configs.${key}`, data[key]);
        }

        setValue(
          'msisdn_whitelist',
          msisdn_whitelist.map((msisdn) => ({ value: msisdn }))
        );
      } catch (e) {}
    }
    fetchConfig();
    // eslint-disable-next-line
  }, []);

  async function onSubmit(data) {
    if (typeof data.msisdn_whitelist === 'undefined') data.msisdn_whitelist = [];
    data.msisdn_whitelist = data.msisdn_whitelist.map((o) => o.value);

    setFormError(null);
    setLoading(true);

    try {
      await backend.put(`/institutions/${user.institution.id}`, data);
      history.push('/webapp/console/config');
    } catch (e) {
      setFormError(e?.data?.error?.description || 'Request failed');
    } finally {
      setLoading(false);
    }
  }

  function addMsisdn() {
    append({ value: '' }, false);
  }

  if (config === null) return <LoadingPage text="Fetching config..." />;

  return (
    <div id="edit-config-page" className="auth-page">
      <Header />
      <div className="header-aware">
        <div className="intro">
          <h2>Edit Config</h2>
        </div>
        <form className="dbx form" onSubmit={handleSubmit(onSubmit)}>
          {formError && (
            <div className="main-error-message">
              <span>
                <i className="fas fa-exclamation-triangle"></i>
              </span>
              <p>* {formError}</p>
            </div>
          )}
          <section>
            <p className="heading-2">Config</p>
            <div className="group">
              <label className="dbx">Credt Callback URL</label>
              <div className="dbx input-container">
                <input type="text" className="dbx" name="configs.credit_callback_url" ref={register} />
                <i className="fas fa-angle-right"></i>
              </div>
            </div>

            <div className="group">
              <label className="dbx">Debit Callback URL</label>
              <div className="dbx input-container">
                <input type="text" className="dbx" name="configs.debit_callback_url" ref={register} />
                <i className="fas fa-angle-right"></i>
              </div>
            </div>

            <div className="group">
              <label className="dbx">IP Address</label>
              <div className="dbx input-container">
                <input type="text" className="dbx" name="configs.ip_address" ref={register} />
                <i className="fas fa-angle-right"></i>
              </div>
            </div>
          </section>

          <section className="msisdn">
            <p className="heading-2">
              Allowed MSISDN {msisdn_whitelist.length < 3 && <i className="fas fa-plus" onClick={addMsisdn}></i>}
            </p>
            <ul>
              {fields.map((field, index) => (
                <li key={field.id}>
                  <div className="dbx input-container">
                    <input
                      type="text"
                      className="dbx"
                      name={`msisdn_whitelist[${index}].value`}
                      ref={register()}
                      defaultValue={field.value}
                    />
                    <i className="fas fa-angle-right"></i>
                  </div>
                  <span className="delete" onClick={() => remove(index)}>
                    <i className="fas fa-times"></i>
                  </span>
                </li>
              ))}
            </ul>
          </section>

          <div style={{ height: '32px' }} />
          <button
            type="submit"
            className={classnames('dbx green rounded right button', { loading })}
            disabled={loading}
          >
            Save changes <i className="fas fa-save"></i>
          </button>
        </form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps)(EditConfigPage);
