import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/img/logo-2.svg';
import illustration from '../../assets/img/illustration-main.svg';
import Circles from '../../components/Circles';

function AuthPage({ children }) {
  return (
    <div className="Page auth-page alt-font-family">
      <div className="big-circle"></div>
      <div className="wrapper">
        <div className="top">
          <img src={logo} alt="Site logo" className="logo" />
          <div className="links">
            <NavLink to="/webapp/auth/login">Sign in</NavLink>
            <NavLink to="/webapp/auth/register">Sign Up</NavLink>
          </div>
        </div>
        <div className="circles circles-top">
          <Circles count={20} />
        </div>
        <div className="circles circles-bottom">
          <Circles count={20} />
        </div>
        <div className="main">
          <img src={illustration} alt="" className="illustration" />

          <div className="main-content">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
