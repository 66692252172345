import React from 'react';

export default function LoadingPage({ text }) {
  return (
    <div id="loading">
      <span>
        <i className="fas fa-spin fa-circle-notch"></i>
      </span>
      <p>{text}</p>
    </div>
  );
}

LoadingPage.defaultProps = {
  text: 'Please wait...',
};
