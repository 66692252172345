import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import Header from '../../components/Header';
import backend from '../../apis/backend';

export default function ChangePasswordPage({ history }) {
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState(null);
  const [loading, setLoading] = useState(null);

  async function onSubmit(data) {
    if (loading) return;
    setLoading(true);

    try {
      await backend.post('/users/change-password', data);
      history.push('/webapp/console/profile');
    } catch (e) {
      setFormError(e?.data?.error?.description || 'Request failed');
      setLoading(false);
    }
  }

  return (
    <div id="login-page" className="auth-page">
      <Header />
      <div className="header-aware">
        <>
          <div className="intro">
            <h2>Change Password</h2>
          </div>
          <form className="dbx form" onSubmit={handleSubmit(onSubmit)}>
            {formError && (
              <div className="main-error-message">
                <span>
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
                <p>* {formError}</p>
              </div>
            )}

            <div className="group">
              <label className="dbx">Current Password:</label>
              <div className="dbx input-container">
                <input type="password" className="dbx" name="current_password" ref={register} />
                <i className="fas fa-key"></i>
              </div>
            </div>

            <div className="group">
              <label className="dbx">New Password:</label>
              <div className="dbx input-container">
                <input type="password" className="dbx" name="new_password" ref={register} />
                <i className="fas fa-key"></i>
              </div>
            </div>

            <div style={{ height: '8px' }} />
            <button className={classnames('dbx green rounded right button', { loading })}>
              Change Password<i className="fas fa-check"></i>
            </button>
          </form>
        </>
      </div>
    </div>
  );
}
