import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function RequireLogoutRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <Redirect to={{ pathname: '/webapp/console/transactions', state: { from: props.location } }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps)(RequireLogoutRoute);
