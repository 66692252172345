import React from 'react';
import Header from '../../../components/Header';
import GithubGist from '../../../components/GithubGist';
import Sidebar from '../../../components/Sidebar';
import HashLinkWithScroll from '../../../components/HashLinkWithScroll';

function renderSideBar() {
  return (
    <Sidebar>
      <nav>
        <ul>
          <li className="section-title">
            <HashLinkWithScroll to="#section-0">
              <span>
                <i className="fas fa-code"></i>
              </span>
              Introduction
            </HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-0-1">Provisioning</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-0-2">Account Funding</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-0-3">Authentication</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-0-4">Send your first request</HashLinkWithScroll>
          </li>
          <li className="section-title">
            <HashLinkWithScroll to="#section-1">
              <span>
                <i className="fas fa-code"></i>
              </span>
              Mobile Money
            </HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-1-1">Credit Request</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-1-2">Debit Request</HashLinkWithScroll>
          </li>
          <li className="section-title">
            <HashLinkWithScroll to="#section-2">
              <span>
                <i className="fas fa-code"></i>
              </span>
              Airtime & Data Bundles
            </HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-1">List Glo Data Bundles</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-2">Buy Glo Data Bundle</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-3">List MTN Data Bundles</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-4">Buy MTN Data Bundle</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-5">Query GOTV account service</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-6">Buy GOTV service</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-7">Query DSTV Account Service</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-8">Buy DSTV Service</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-9">Buy Surfline Bundle Service</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-10">Pay Surfline Bundle Service</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-11">Pay Vodafone Postpaid</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-12">Query Busy Internet Bundles</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-13">Pay Busy Internet Bundles</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-14">Query Vodafone Broadband Service</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-15">Pay Vodafone Broadband Service</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-16">Query Vodafone Bundles Service</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-17">Pay Vodafone Bundles Service</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-18">List MTN Fibre Broadband</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-19">Buy MTN Fibre Broadband</HashLinkWithScroll>
          </li>
          <li>
            <HashLinkWithScroll to="#section-2-20">Buy Airtime</HashLinkWithScroll>
          </li>
        </ul>
      </nav>
    </Sidebar>
  );
}

export default function ReadDocsPage({ location }) {
  return (
    <div className="header-aware">
      <div id="read-docs-page">
        <Header />

        <main>
          {renderSideBar()}
          <div className="main-content sidebar-aware">
            <div className="wrapper">
              <section id="section-0">
                <p className="docs-heading-1">Introduction</p>
                <p className="docs-text-1">
                  The Nsano Mobile Money APIs allow applications connected to debit and credit mobile money wallets and
                  bank accounts. The APIs are hosted on Nsano’s proprietary platform known as Fusion. The supported
                  mobile money wallets are listed in the course of the document.
                </p>
                <p className="docs-text-1">
                  The APIs are designed based on the REST architecture and can be accessed via an HTTPS URL by sending
                  x-www-form-urlencoded parameters using the POST/GET method. Responses returned are JSON encoded.
                </p>
              </section>
              <section id="section-0-1">
                <p className="docs-heading-2">Provisioning</p>
                <p className="docs-text-1">
                  Third parties are provisioned for access to the APIs after their details are collected in a Request
                  for Information (RFI) form. The API endpoint and API key are shared via mail after provisioning is
                  completed.
                </p>
              </section>
              <section id="section-0-2">
                <p className="docs-heading-2">Account Funding</p>
                <p className="docs-text-1">
                  Third parties are to fund their accounts for mobile money credit tests. Credit transactions will be
                  performed against the balance on their accounts.
                </p>
              </section>
              <section id="section-0-3">
                <p className="docs-heading-2">Authentication</p>
                <p className="docs-text-1">
                  The APIs use an API Key for authentication. The API key is generated after the third party is
                  provisioned on the platform and is shared at the start of integration. The third party is responsible
                  for keeping the API key secure at all times. Also, access to the APIs is restricted to whitelisted
                  dedicated static IP addresses, provided by the third party only.
                </p>
              </section>
              <section id="section-0-4">
                <p className="docs-heading-2">Send your first request</p>
                <p className="docs-text-1">
                  Once you have your credentials and you have funds in your account, you can send your first request.
                </p>
              </section>
              <section id="section-1">
                <p className="docs-heading-1">Mobile Money</p>
              </section>
              <section id="section-1-1">
                <p className="docs-heading-2">Credit Request</p>
                <p className="docs-text-1">This request is used to credit a mobile money wallet.</p>
                <ul className="definitions">
                  <li className="definition">
                    <span className="key">URL:</span>
                    <span className="value">{'https://IP:Port/api/fusion/tp/<apiKey>'}</span>
                  </li>
                  <li className="definition">
                    <span className="key">Headers:</span>
                    <span className="value"></span>
                  </li>
                  <li className="definition">
                    <span className="key">Method:</span>
                    <span className="value">POST</span>
                  </li>
                  <li className="definition">
                    <span className="key">Content-Type:</span>
                    <span className="value">x-www-form-urlencoded</span>
                  </li>
                  <li className="definition">
                    <span className="key">Request parameters data type:</span>
                    <span className="value">String</span>
                  </li>
                </ul>

                <div className="docs-table">
                  <p className="docs-heading-4">Request parameters:</p>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Parameter</th>
                        <th scope="col">Description</th>
                        <th scope="col">Required</th>
                        <th scope="col">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>API String</td>
                        <td>Yes</td>
                        <td>mikopo</td>
                      </tr>
                      <tr>
                        <th scope="row">amount</th>
                        <td>Transaction amount (up to 2dp)</td>
                        <td>Yes</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">mno</th>
                        <td>Mobile Network Operator</td>
                        <td>Yes</td>
                        <td>MTN, AIRTEL, VODAFONE</td>
                      </tr>
                      <tr>
                        <th scope="row">refID</th>
                        <td>Third party transaction reference ID</td>
                        <td>Yes</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">msisdn</th>
                        <td>Wallet to be credited</td>
                        <td>Yes</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample request:</p>
                <GithubGist src="https://gist.github.com/crisshaker/87e97222e413ac3ef8d82ea8aa41eae3.js" />

                <p className="docs-heading-3">Sample Response</p>
                <p className="docs-heading-4">Synchronous Response:</p>
                <p className="docs-text-1">
                  An initial response will be sent in acknowledgement of the credit request. A sample of the initial
                  response is shown below:
                </p>
                <GithubGist src="https://gist.github.com/crisshaker/90314797d2006cf1aa9ce9c37910534d.js" />

                <div className="docs-table">
                  <p className="docs-heading-4">Synchronous response parameters:</p>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Parameter</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">msg</th>
                        <td>Response message</td>
                      </tr>
                      <tr>
                        <th scope="row">code</th>
                        <td>Response code</td>
                      </tr>
                      <tr>
                        <th scope="row">reference</th>
                        <td>Response reference</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-4">Asynchronous Response:</p>
                <p className="docs-text-1">
                  The third party has to provide a callback URL to receive the final transaction response. A sample of
                  the final response is shown below:
                </p>
                <GithubGist src="https://gist.github.com/crisshaker/fb3daa1b468049ad6bc30464a83360c2.js" />
                <div className="docs-table">
                  <p className="docs-heading-4">Asynchronous response parameters:</p>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Parameter</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">msg</th>
                        <td>Response message</td>
                      </tr>
                      <tr>
                        <th scope="row">reference</th>
                        <td>Response reference</td>
                      </tr>
                      <tr>
                        <th scope="row">code</th>
                        <td>Response code</td>
                      </tr>
                      <tr>
                        <th scope="row">system_code</th>
                        <td>Telco response code</td>
                      </tr>
                      <tr>
                        <th scope="row">system_msg</th>
                        <td>Telco response message</td>
                      </tr>
                      <tr>
                        <th scope="row">transactionID</th>
                        <td>Telco transaction ID</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-text-1">
                  The third party responds to the call back with the parameters code and msg JSON formatted to close the
                  transaction as shown below:
                </p>
                <GithubGist src="https://gist.github.com/crisshaker/67c549c409f1ea6af9e4d57b9865a008.js" />
              </section>

              <section id="section-1-2">
                <p className="docs-heading-2">Debit Request</p>
                <p className="docs-text-1">This request is used to debit a mobile money wallet.</p>
                <ul className="definitions">
                  <li className="definition">
                    <span className="key">URL:</span>
                    <span className="value">{'https://IP:Port/api/fusion/tp/<apiKey>'}</span>
                  </li>
                  <li className="definition">
                    <span className="key">Headers:</span>
                    <span className="value"></span>
                  </li>
                  <li className="definition">
                    <span className="key">Method:</span>
                    <span className="value">POST</span>
                  </li>
                  <li className="definition">
                    <span className="key">Content-Type:</span>
                    <span className="value">x-www-form-urlencoded</span>
                  </li>
                  <li className="definition">
                    <span className="key">Request parameters data type:</span>
                    <span className="value">String</span>
                  </li>
                </ul>
                <div className="docs-table">
                  <p className="docs-heading-4">Request parameters:</p>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Parameter</th>
                        <th scope="col">Description</th>
                        <th scope="col">Required</th>
                        <th scope="col">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>API String</td>
                        <td>Yes</td>
                        <td>malipo</td>
                      </tr>
                      <tr>
                        <th scope="row">amount</th>
                        <td>Transaction amount (up to 2dp)</td>
                        <td>Yes</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">mno</th>
                        <td>Mobile Network Operator</td>
                        <td>Yes</td>
                        <td>MTN, AIRTEL, VODAFONE</td>
                      </tr>
                      <tr>
                        <th scope="row">refID</th>
                        <td>Third party transaction reference ID</td>
                        <td>No</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">msisdn</th>
                        <td>Wallet to be debited</td>
                        <td>Yes</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">authToken</th>
                        <td>Authentication token by Vodafone. Not required for approval via USSD prompt</td>
                        <td>No</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="docs-heading-3">Sample request</p>
                <GithubGist src="https://gist.github.com/crisshaker/ac39dbd8965df0f2f6cf44460c1b9996.js" />
                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">Synchronous response:</p>
                <p className="docs-text-1">
                  An initial response will be sent in acknowledgement of the debit request. A sample of the initial
                  response is shown below:
                </p>
                <GithubGist src="https://gist.github.com/crisshaker/a1e316893f9e8240aa3d01150cc628f9.js" />
                <div className="docs-table">
                  <p className="docs-heading-4">Synchronous response parameters:</p>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Parameter</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">msg</th>
                        <td>Response message</td>
                      </tr>
                      <tr>
                        <th scope="row">code</th>
                        <td>Response code</td>
                      </tr>
                      <tr>
                        <th scope="row">reference</th>
                        <td>Response reference</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="docs-heading-4">Asynchronous response:</p>
                <p className="docs-text-1">
                  The third party has to provide a callback URL to receive the final transaction response. The final
                  response will contain the same reference as the initial response. A sample of the final response is
                  shown below:
                </p>
                <GithubGist src="https://gist.github.com/crisshaker/8f0173a03bc882cfa350af0533fbe012.js" />
                <div className="docs-table">
                  <p className="docs-heading-4">Asynchronous response parameters:</p>
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Parameter</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">msg</th>
                        <td>Response message</td>
                      </tr>
                      <tr>
                        <th scope="row">reference</th>
                        <td>Response reference</td>
                      </tr>
                      <tr>
                        <th scope="row">code</th>
                        <td>Response code</td>
                      </tr>
                      <tr>
                        <th scope="row">system_code</th>
                        <td>Telco response code</td>
                      </tr>
                      <tr>
                        <th scope="row">system_msg</th>
                        <td>Telco response message</td>
                      </tr>
                      <tr>
                        <th scope="row">transactionID</th>
                        <td>Telco transaction ID</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="docs-text-1">
                  The third party responds to the call back with the parameters code and msg JSON formatted to close the
                  transaction as shown below:
                </p>
                <GithubGist src="https://gist.github.com/crisshaker/67c549c409f1ea6af9e4d57b9865a008.js" />
                <p className="docs-text-1">The accepted options for code are shown and explained below:</p>
                <ul className="docs-list">
                  <li>
                    Send a response code of 00 – In this case, the third party has completed the other leg of the
                    transaction on their end and the transaction ends.
                  </li>
                  <li>
                    Send a response code of 01 / 02 – In this case, either the third party could not complete the other
                    leg of the transaction on their end or the other leg failed so the amount is reversed to the
                    customer.
                  </li>
                  <li>
                    Send a response code of 03 – In this case the third party has to call the Notification API after
                    other leg of the transaction (or any internal operation for which the debit API was called) is
                    completed on their end
                  </li>
                </ul>
              </section>

              <section id="section-2">
                <p className="docs-heading-1">Airtime & Data</p>
              </section>
              <section>
                <p className="docs-heading-2">Overview</p>
                <p className="docs-text-1">
                  This document is intended to list and explain the use of APIs given out to third parties for
                  integration with Integration Center services.
                </p>
                <p className="docs-text-1">The services can be accessed via the URL formatted as follows:</p>
                <p className="docs-text-1">
                  <strong>https://fs1.nsano.com:5001</strong>
                </p>
                <p className="docs-text-1">
                  An API key is generated after the third party is provisioned on the platform.
                </p>
                <p className="docs-text-1">
                  The API key, IP and Port would be provided at the point of integration. All parameters described are
                  to be sent via POST and the data type should be “String”.
                </p>
              </section>
              <section>
                <p className="docs-heading-2">API Details</p>
                <p className="docs-heading-3">Procure Service</p>
                <p className="docs-text-1">
                  This method is used to deliver/pay for a service on behalf of a customer. For example, ECG, DStv, GOtv
                  etc
                </p>

                <div className="docs-table">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Parameter</th>
                        <th scope="col">Description</th>
                        <th scope="col">Required</th>
                        <th scope="col">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>API String</td>
                        <td></td>
                        <td>malipo</td>
                      </tr>
                      <tr>
                        <th scope="row">sid</th>
                        <td>ID of service being requested for</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>Service specific json object</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">refID</th>
                        <td>Third party transaction reference ID</td>
                        <td></td>
                        <td>Auto-generated</td>
                      </tr>
                      <tr>
                        <th scope="row">msisdn</th>
                        <td>Wallet to be credited</td>
                        <td>Yes</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="docs-heading-3">Return messages and codes</p>
                <p className="docs-text-1">
                  The API will always return a JSON Object containing a response code and message. code corresponds to
                  Fusion’s event code and indicates the status of the request. msg contains the appropriate message
                  related to the event.
                </p>
                <div className="docs-table">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Code</th>
                        <th scope="col">Definition</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">00</th>
                        <td>Service request is completed</td>
                      </tr>
                      <tr>
                        <th scope="row">01</th>
                        <td>Service request failed</td>
                      </tr>
                      <tr>
                        <th scope="row">02</th>
                        <td>Internal error occured</td>
                      </tr>
                      <tr>
                        <th scope="row">03</th>
                        <td>Pending</td>
                      </tr>
                      <tr>
                        <th scope="row">05</th>
                        <td>Unauthorized access</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-text-1">
                  An optional attribute data is returned when the service results contains extra elements in the
                  response. The data parameter is a JSON object.
                </p>
                <p className="docs-text-1">
                  An optional attribute metadataID used to reference the request performed when the service call is made
                  asynchronously.
                </p>
                <p className="docs-text-1">
                  An optional attribute system_code is returned only when service has been performed.
                </p>

                <div className="docs-table">
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">system_code</th>
                        <th scope="col">Definition</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">PS010</th>
                        <td>Service call was performed and results obtained</td>
                      </tr>
                      <tr>
                        <th scope="row">PS007</th>
                        <td>An error occured before service call was made</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="docs-heading-3">Asynchronous service calls</p>
                <p className="docs-text-1">Fusion API supports asynchronous calls.</p>
                <p className="docs-text-1">
                  For a third party to perform an asynchronous call, a notification URL must be shared with NSANO. The
                  URL will receive calls when the service request has been completed.
                </p>
                <p className="docs-text-1">
                  To initiate a request asynchronously, the serviceDetails parameter should contain an attribute sync
                  with value: "sync":false
                </p>
                <p className="docs-text-1">
                  Fusion will return a JSON Object with code 03. The value of msg will be a metadataID that will be used
                  for reference when fusion sends a call back on the URL provided.
                </p>
                <p className="docs-text-1">The URL should expect data to be sent via POST and in a JSON Format.</p>
              </section>

              <section id="section-2-1">
                <p className="docs-heading-2">1. List GLO Data Bundle</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>LGDB1538684683076</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <section id="section-2-2">
                <p className="docs-heading-2">2. Buy GLO Data Bundle</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>BGDB1538684523996</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'{"msisdn":<phone_number>","data_bundle_type":"bundleID","amount":"20"}'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                      <tr>
                        <th scope="row">refID</th>
                        <td>000001 (Optional, will be auto generated when not provided)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <section id="section-2-3">
                <p className="docs-heading-2">3. List MTN Data Bundles</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>BMDB1538684513660</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'"msisdn":<phone_number>","data_bundle_type":"bundleID","amount":"20"'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                      <tr>
                        <th scope="row">refID</th>
                        <td>000001 (Optional, will be auto generated when not provided)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <section id="section-2-4">
                <p className="docs-heading-2">4. Buy MTN Data Bundle</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>LMDB1538684698691</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <section id="section-2-5">
                <p className="docs-heading-2">5. Query GOTV account service</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>QGOTVA1540375336697</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'{"customerNumber":"23320XXXXXXX"}'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Parameters values correct</p>
                <GithubGist src="https://gist.github.com/crisshaker/23035aa2b0c2e927a09a15aeed2518b4.js" />
              </section>

              <section id="section-2-6">
                <p className="docs-heading-2">6. Buy GOTV service</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>GOTV1539275563100</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'{"customerNumber":"23320XXXXXXX","is_monthly":"true","amount":"0.5"}'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">
                  SCENARIO 1: Invalid amount (amount passed was below the minimum allowed)
                </p>
                <GithubGist src="https://gist.github.com/crisshaker/6d2fa4f0d4873536f2c405ff716564d5.js" />

                <p className="docs-heading-4">SCENARIO 2: Invalid amount (amount passed was not a number)</p>
                <GithubGist src="https://gist.github.com/crisshaker/aca102f3440a875858c40d6459ea3946.js" />

                <p className="docs-heading-4">SCENARIO 3: Invalid customer account</p>
                <GithubGist src="https://gist.github.com/crisshaker/911cd3b16823bf7042c36f14b594ad36.js" />

                <p className="docs-heading-4">SCENARIO 4: Missing parameter in the "serviceDetails”</p>
                <GithubGist src="https://gist.github.com/crisshaker/23b89826394fda283e2ad67b9b72e2a8.js" />

                <p className="docs-heading-4">SCENARIO 5: Successful service payment</p>
                <GithubGist src="https://gist.github.com/crisshaker/996c7dc06c34ec794f75ffb383be5b15.js" />
              </section>

              <section id="section-2-7">
                <p className="docs-heading-2">7. Query DSTV Account Service</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>QDSTVA1540372766377</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'{"customerNumber":"XXXXXXXXXX"}'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Parameters values correct</p>
                <GithubGist src="https://gist.github.com/crisshaker/ca2c365ef789362559196594bee70791.js" />
              </section>

              <section id="section-2-8">
                <p className="docs-heading-2">8. Buy DSTV Service</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>DSTV1539275545319</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'{"customerNumber":"XXXXXXXXXX","is_monthly":"true","amount":"1"}'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">
                  SCENARIO 1: Invalid amount (amount passed was below the minimum allowed)
                </p>
                <GithubGist src="https://gist.github.com/crisshaker/6d83b44e87daaa75637aa0782036d225.js" />

                <p className="docs-heading-4">SCENARIO 2: Invalid amount (amount passed was not a number</p>
                <GithubGist src="https://gist.github.com/crisshaker/ac30e8cda5d55a23d3fb4bee9065978f.js" />

                <p className="docs-heading-4">SCENARIO 3: Invalid customer account</p>
                <GithubGist src="https://gist.github.com/crisshaker/5a6ee4f2349b7efc2e10b58dc68e1635.js" />

                <p className="docs-heading-4">SCENARIO 4: Missing parameter in the "serviceDetails"</p>
                <GithubGist src="https://gist.github.com/crisshaker/c93cac264609eff9a367c5ca32d03801.js" />

                <p className="docs-heading-4">SCENARIO 5: Successful service payment</p>
                <GithubGist src="https://gist.github.com/crisshaker/60359f66721e1ed539926c61b1909c86.js" />
              </section>

              <section id="section-2-9">
                <p className="docs-heading-2">9. Buy Surfline Bundle Service</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>QSP1539615674656</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'{"accountno":"00000","is_monthly":"true","amount":"0"}'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Invalid Surfline number</p>
                <GithubGist src="https://gist.github.com/crisshaker/05095855e33ca90679084d672794bfc1.js" />

                <p className="docs-heading-4">SCENARIO 2: Valid Surfline number (test account used was 0255000102)</p>
                <GithubGist src="https://gist.github.com/crisshaker/4c89bfd8e2274961346f5db0b6212d36.js" />
              </section>

              <section id="section-2-10">
                <p className="docs-heading-2">10. Pay Surfline Bundle Service</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>PSB1539615469396</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>
                          serviceDetails:
                          {'{"accountno":"0255000102","data_bundle_type":"ulvideoweekly","extID":"","amount":" 0"}'}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">
                  SCENARIO 1: Parameters values not correct (like wrong data_bundle_type)
                </p>
                <GithubGist src="https://gist.github.com/crisshaker/94b91e099e3b6c3c328326bfdb0e43d8.js" />

                <p className="docs-heading-4">SCENARIO 2: Successful payment (test account used was 0255000102)</p>
                <GithubGist src="https://gist.github.com/crisshaker/d1dd0526755f90d053f7734c4759bc3c.js" />
              </section>

              <section id="section-2-11">
                <p className="docs-heading-2">11. Pay Vodafone Postpaid</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>VP1557751505479</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>
                          serviceDetails:
                          {
                            '{"accountref":"0302810037","mobile":"23320XXXXXXX","email":"test@nsano.com"," amount":"0.1"}'
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Successful Transaction</p>
                <GithubGist src="https://gist.github.com/crisshaker/1983a09f0f31a51a2e66c8ad447d0bc3.js" />
              </section>

              <section id="section-2-12">
                <p className="docs-heading-2">12. Query Busy Internet Bundles</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>QBB1557933041054</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'{"accountRef":"121209","subscriberMobile":"23354XXXXXXX"}'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Successful Transaction</p>
                <GithubGist src="https://gist.github.com/crisshaker/2482904250eb16daca027a741d3846d7.js" />
              </section>

              <section id="section-2-13">
                <p className="docs-heading-2">13. Pay Busy Internet Bundles</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>BI1557750879845</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>
                          {'{"accountref":"121209","mobile":"23320XXXXXXX","email":"test@nsano.com","amount":"5.00"}'}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Successful Transaction</p>
                <GithubGist src="https://gist.github.com/crisshaker/5b9d5103ec1c388f63da184a63ad7418.js" />
              </section>

              <section id="section-2-14">
                <p className="docs-heading-2">14. Query Vodafone Broadband Service</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>QVBP1557932790402</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'{"accountRef":"0302810037","subscriberMobile":"23354XXXXXXX"}'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Successful Transaction</p>
                <GithubGist src="https://gist.github.com/crisshaker/0419145c08c4865847b8973beeada0a4.js" />
              </section>

              <section id="section-2-15">
                <p className="docs-heading-2">15. Pay Vodafone Broadband Service</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>VB1557751518035</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>
                          {
                            '{"accountref":"030XXXXXXX ","mobile":"23320XXXXXXX",email":"test@nsano.com","amount":"0.1"}'
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Successful Transaction</p>
                <GithubGist src="https://gist.github.com/crisshaker/0b99fa9f3ae4bc856b4aeb9b811e0f00.js" />
              </section>

              <section id="section-2-16">
                <p className="docs-heading-2">16. Query Vodafone Bundles Service</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>LVDB1563533103268</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Successful Transaction</p>
                <GithubGist src="https://gist.github.com/crisshaker/19ad5112e939dbdd03df298f2ed538e4.js" />
              </section>

              <section id="section-2-17">
                <p className="docs-heading-2">17. Pay Vodafone Bundles Service</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>BVDB1570454271734</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'{"msisdn":"23320XXXXXXX", "data_bundle_type":"STARTER_MONTHLY","amount":"0.5"}'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Successful Transaction</p>
                <GithubGist src="https://gist.github.com/crisshaker/9ed7d4e384fadee64194db51e5e985bd.js" />
              </section>

              <section id="section-2-18">
                <p className="docs-heading-2">18. List MTN Fibre Broadband</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>LMFB1570608867466</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Successful Transaction</p>
                <GithubGist src="https://gist.github.com/crisshaker/583ca0aa3b846657fae1fb300b0c91d0.js" />
              </section>

              <section id="section-2-19">
                <p className="docs-heading-2">19. Buy MTN Fibre Broadband</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>BMFDB1570610071459</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>{'{"msisdn":"23324XXXXXXX","data_bundle_type":"bundleID","amount":"20"}'}</td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                      <tr>
                        <th scope="row">refID</th>
                        <td>000001 (Optional, will be auto generated when not provided)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <section id="section-2-20">
                <p className="docs-heading-2">20. Buy Airtime</p>
                <div className="docs-table">
                  <p className="docs-heading-4">Sample Request:</p>
                  <table>
                    <tbody>
                      <tr>
                        <th scope="row">sid</th>
                        <td>BAE0021539718847270</td>
                      </tr>
                      <tr>
                        <th scope="row">serviceDetails</th>
                        <td>
                          {
                            '{“institution_id”:170728075107, “api_key”: “XXXXXXXXXX”, “msisdn”: “233208775408”, “network”: “MTN | VODAFONE | AIRTEL | TIGO | GLO”, “amount”: “10”, “extRefID”:”RAND0115072020”}'
                          }
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">kuwaita</th>
                        <td>huduma_mkoba</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="docs-heading-3">Sample response</p>
                <p className="docs-heading-4">SCENARIO 1: Successful Payment</p>
                <GithubGist src="https://gist.github.com/crisshaker/5472eb7c1a924547fc34ee81f4c7b342.js" />

                <p className="docs-heading-4">
                  SCENARIO 2: Invalid amount (amount passed was below the minimum allowed)
                </p>
                <GithubGist src="https://gist.github.com/crisshaker/ad7d7d9b666664090f13b8c1b380dc4d.js" />
              </section>
            </div>
          </div>
        </main>
      </div>

      <div style={{ height: '200px' }}></div>
    </div>
  );
}
