import React from 'react';
import { useFormContext } from 'react-hook-form';
import ghanaFlag from '../../assets/img/ghana-flag.png';

function OrganizationSignUp() {
  const { register } = useFormContext();

  return (
    <>
      <input name="category" value="ORGANIZATION" hidden readOnly />

      <div className="group">
        <label className="dbx-2">Name of Instituion</label>
        <input className="dbx-2" type="text" name="name" autoFocus ref={register} />
      </div>

      <div className="group">
        <label className="dbx-2">Your Name:</label>
        <input className="dbx-2" type="text" name="contact.name" ref={register} />
      </div>

      <div className="group">
        <label className="dbx-2">Email Address</label>
        <input className="dbx-2" type="text" inputMode="email" name="contact.email" ref={register} />
      </div>

      <div className="group">
        <label className="dbx-2">Phone number</label>
        <input className="dbx-2" type="text" inputMode="tel" name="contact.msisdn" ref={register} />
      </div>

      <div className="group">
        <label className="dbx-2">Average Transaction Volume </label>
        <div className="dbx-2 select">
          <select name="tier" ref={register}>
            <option value="TIER_1">GHS 0-5000</option>
            <option value="TIER_2">GHS 5001-15,000</option>
            <option value="TIER_3">GHS 15,001 and above</option>
          </select>
        </div>
      </div>

      <div className="group">
        <label className="dbx-2">Physical Address (Ghana post GPS)</label>
        <input className="dbx-2" type="text" name="contact.address" ref={register} />
      </div>

      <div className="group country">
        <label className="dbx-2">Country</label>

        <div className="input-container">
          <div className="dbx-2 select">
            <select name="country" ref={register}>
              <option value="GH">Ghana</option>
            </select>
          </div>
          <img src={ghanaFlag} alt="" />
        </div>
      </div>
    </>
  );
}

export default OrganizationSignUp;
