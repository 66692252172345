import React from 'react';

export default function Circles({ count }) {
  return (
    <>
      {Array(count)
        .fill(null)
        .map((_, index) => (
          <div className="circle" key={index}></div>
        ))}
    </>
  );
}
