import React from 'react';

export default function Loading({ text }) {
  return (
    <div className="dbx-loading">
      <span>
        <i className="fas fa-spin fa-circle-notch"></i>
      </span>
      <p>{text}</p>
    </div>
  );
}

Loading.defaultProps = {
  text: 'Please wait...',
};
