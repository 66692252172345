import React from 'react';
import profilePicture from '../../assets/img/dp.png';
import metrics from '../../assets/img/metrics.svg';
import currency from '../../assets/img/currency-exchange-2.svg';
import analytics from '../../assets/img/analytics.svg';
import rocket from '../../assets/img/rocket.svg';
import logout from '../../assets/img/logout.svg';

export default function TransactionsPage() {
  return (
    <div className="TransactionsPage Page alt-font-family">
      <div className="admin-header">
        <nav className="admin-nav">
          <ul>
            <li>
              <a href="#0">
                <img src={metrics} alt="" />
                Dashboard
              </a>
            </li>
            <li>
              <a href="#0" className="active">
                <img src={currency} alt="" />
                Transactions
              </a>
            </li>
            <li>
              <a href="#0">
                <img src={metrics} alt="" />
                Documentation
              </a>
            </li>
            <li>
              <a href="#0">
                <img src={analytics} alt="" />
                Account Management
              </a>
            </li>
            <li className="go-live button">
              <a href="#0">
                <img src={rocket} alt="" />
                Go Live
              </a>
            </li>
            <li>
              <a href="#0">
                <img src={logout} alt="" />
                Logout
              </a>
            </li>
          </ul>
        </nav>

        <div className="left">
          <button className="nav-trigger">
            <div className="circles">
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
            </div>
          </button>
        </div>
        <div className="right">
          <img src={profilePicture} className="profile-picture" alt="" />
          <span className="name">Okatakyie O. Badu</span>
          <div className="user-dropdown">
            <div className="circles">
              <div className="circle"></div>
              <div className="circle"></div>
              <div className="circle"></div>
            </div>

            <div className="menu">
              <p className="email">nomadcreations18@gmail.com</p>
              <ul>
                <li>
                  <button type="button" className="view-profile">
                    View Profile<i className="fas fa-user-circle"></i>
                  </button>
                </li>
                <li>
                  <button type="button" className="change-password">
                    Change Password<i className="fas fa-undo"></i>
                  </button>
                </li>
                <li>
                  <button type="button" className="logout">
                    Log out<i className="fas fa-sign-out-alt"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="admin-header-aware">
        <div className="main">
          <div className="admin-heading">
            <h2>Transactions</h2>
            <p>Welcome back, Okatakyie</p>
          </div>

          <div className="dbx-2-transactions-table">
            <table>
              <thead>
                <tr>
                  <th scope="col">
                    Transaction ID
                    <span>
                      <i className="fas fa-arrow-down"></i>
                    </span>
                  </th>
                  <th scope="col">
                    Transaction Type
                    <span>
                      <i className="fas fa-arrow-down"></i>
                    </span>
                  </th>
                  <th scope="col">
                    Amount
                    <span>
                      <i className="fas fa-arrow-down"></i>
                    </span>
                  </th>
                  <th scope="col">
                    Status
                    <span>
                      <i className="fas fa-arrow-down"></i>
                    </span>
                  </th>
                  <th scope="col">
                    Customer Name
                    <span>
                      <i className="fas fa-arrow-down"></i>
                    </span>
                  </th>
                  <th scope="col">
                    Customer Phone Number
                    <span>
                      <i className="fas fa-arrow-down"></i>
                    </span>
                  </th>
                  <th scope="col">
                    Channel
                    <span>
                      <i className="fas fa-arrow-down"></i>
                    </span>
                  </th>
                  <th scope="col">
                    Date Created
                    <span>
                      <i className="fas fa-arrow-down"></i>
                    </span>
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>465aedw45w4e56516d2df1</td>
                  <td>DEPOSIT</td>
                  <td className="amount">
                    <span>GHS 200</span>
                  </td>
                  <td className="status FAILED">FAILED</td>
                  <td>Michael Scott</td>
                  <td>0551696369</td>
                  <td>SELF</td>
                  <td>07/04/2020</td>
                  <td className="action">
                    <div className="circles">
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>165w4ee452df465a1dw6d5</td>
                  <td>DEPOSIT</td>
                  <td className="amount">
                    <span>GHS 889,990.200</span>
                  </td>
                  <td className="status SUCCESSFUL">SUCCESSFUL</td>
                  <td>Stanley Hudson</td>
                  <td>0551696369</td>
                  <td>SELF</td>
                  <td>07/04/2020</td>
                  <td className="action">
                    <div className="circles">
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>62df45w4ee4565a1dw6d51</td>
                  <td>DEPOSIT</td>
                  <td className="amount">
                    <span>GHS 8,990</span>
                  </td>
                  <td className="status PENDING">PENDING</td>
                  <td>Jim Halpert</td>
                  <td>0551696369</td>
                  <td>N/A</td>
                  <td>07/04/2020</td>
                  <td className="action">
                    <div className="circles">
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>465aedw45w4e56516d2df1</td>
                  <td>DEPOSIT</td>
                  <td className="amount">
                    <span>GHS 200</span>
                  </td>
                  <td className="status FAILED">FAILED</td>
                  <td>Michael Scott</td>
                  <td>0551696369</td>
                  <td>SELF</td>
                  <td>07/04/2020</td>
                  <td className="action">
                    <div className="circles">
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>165w4ee452df465a1dw6d5</td>
                  <td>DEPOSIT</td>
                  <td className="amount">
                    <span>GHS 889,990.200</span>
                  </td>
                  <td className="status SUCCESSFUL">SUCCESSFUL</td>
                  <td>Stanley Hudson</td>
                  <td>0551696369</td>
                  <td>SELF</td>
                  <td>07/04/2020</td>
                  <td className="action">
                    <div className="circles">
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>62df45w4ee4565a1dw6d51</td>
                  <td>DEPOSIT</td>
                  <td className="amount">
                    <span>GHS 8,990</span>
                  </td>
                  <td className="status PENDING">PENDING</td>
                  <td>Jim Halpert</td>
                  <td>0551696369</td>
                  <td>N/A</td>
                  <td>07/04/2020</td>
                  <td className="action">
                    <div className="circles">
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
