import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../common';

export default function HashLinkWithScroll({ children, ...props }) {
  return (
    <HashLink {...props} scroll={scrollWithOffset}>
      {children}
    </HashLink>
  );
}
